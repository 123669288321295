<template>
  <div class="feed-recommended-sidebar">
    <h1 class="feed-recommended-sidebar__title">
      Recommended Contributors
    </h1>
    <div class="recommended">
      <div class="recommended__header">
        <h3 class="recommended__header--title">
          Recommended Connections
        </h3>
      </div>
      <div class="recommended__body">
        <template v-if="recommendedLoader">
          <skeleton-loader
            v-for="index in 3"
            :key="index"
          />
        </template>
      <template v-else>
        <div class="recommended-item" v-for="connection in recommendedConnections.slice(0,4)" :key="connection.id" @click="redirectToUser(connection)">
          <div class="recommended-item__image">
            <img
              :src="connection.profile_picture"
              alt="Logo"
              class="recommended-item__image--img"
            />
          </div>
          <div class="recommended-item__details">
            <h2 class="recommended-item__details--name">{{connection.full_name}}</h2>
          </div>
        </div>
      </template>
      </div>
    </div>
    <div class="recommended">
      <div class="recommended__header">
        <h3 class="recommended__header--title">
          Recommended Organizations
        </h3>
      </div>
      <div class="recommended__body">
        <template v-if="recommendedLoader">
          <skeleton-loader
            v-for="index in 3"
            :key="index"
          />
        </template>
        <template v-else>
          <div class="recommended-item" v-for="organization in recommendedOrganizations.slice(0,4)" :key="organization?.id">
            <div class="recommended-item__image">
              <img
                :src="organization.picture"
                alt="Logo"
                class="recommended-item__image--img"
              />
            </div>
            <div class="recommended-item__details">
              <h2 class="recommended-item__details--name">{{organization.name}}</h2>
              <p class="recommended-item__details--description">
                {{ organization.message }}
              </p>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import skeletonLoader from "../BaseComponents/ConnectionCardSkeleton.vue"
export default{
  components:{
    skeletonLoader
  },
  data: () =>({
    recommendedLoader: false
  }),
  computed:{
    ...mapGetters({
      recommendedConnections: 'feed/recommendedConnections',
      recommendedOrganizations: 'feed/recommendedOrganizations'
    })
  },
  async mounted(){
    this.recommendedLoader = true
    await this.fetchRecommendedConnections()
    await this.fetchRecommendedOrganization()
    this.recommendedLoader = false
  },
  methods:{
    ...mapActions({
      fetchRecommendedConnections:'feed/fetchRecommendedConnections',
      fetchRecommendedOrganization:'feed/fetchRecommendedOrganization',
      setSelectedUser:'profile/setSelectedUser'
    }),
    redirectToUser(user) {

      this.setSelectedUser(user)
      this.$router.push({
        path: `/user/${user.username}/`
      });
      this.getUserInformation();
    },
    async getUserInformation() {
      try {
        await this.$store.dispatch("profile/fetchUserProfile");
        await this.$store.dispatch("profile/loadExperiences");
        await this.$store.dispatch("profile/getAddedLanguages");
        await this.$store.dispatch("profile/fetchPhotoResume");
      } catch (err) {
        console.log(err);
      }
    },
  }
}
</script>

<style lang="scss">
.feed-recommended-sidebar {
  border: 1px solid $color-dark-grey-5;
  border-radius: 2rem;
  background-color: $color-white;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  position: sticky;
  top: 0;
  height: calc(100vh - 14.5rem);
  overflow-y: auto;
  &__title {
    padding: 1.7rem 2.8rem;
    font-size: 1.8rem;
    font-family: $font-primary-bold;
    margin-bottom: 0;
    line-height: 2.6rem;
    color: $color-black;
    position: sticky;
    top: 0;
    background-color: $color-white;
    z-index: 1;
  }
  .recommended {
    padding: 2.6rem 3.1rem;
    border-top: 1px solid $color-dark-grey-5;
    &__header {
      margin-bottom: 2.4rem;
      &--title {
        font-size: 1.8rem;
        font-family: $font-primary-medium;
        margin-bottom: 0;
        line-height: 2.1rem;
        color: $color-black;
      }
    }
    &__body {
      display: flex;
      flex-direction: column;
      gap: 2.4rem;
      .recommended-item {
        display: flex;
        align-items: center;
        gap: 1.6rem;
        &__image {
          width: 4.8rem;
          height: 4.8rem;
          border-radius: 100%;
          cursor: pointer;
          &--img {
            width: 100%;
            height: 100%;
            border-radius: 100%;
            object-fit: cover;
          }
        }
        &__details {
          width: calc(100% - 4.8rem);
          &--name {
            font-size: 1.6rem;
            font-family: $font-primary-bold;
            color: $color-black;
            line-height: 1.8rem;
            margin-bottom: 0;
            cursor: pointer;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 1;
                    line-clamp: 1; 
            -webkit-box-orient: vertical;
          }
          &--description {
            font-size: 1.2rem;
            font-family: $font-primary;
            color: $color-black;
            line-height: 1.5rem;
            margin-bottom: 0;
            opacity: 0.7;
            margin-top: 0.5rem;
          }
        }
      }
      .connection-card-skeleton {
        margin: 0 !important;
        .ant-skeleton-header {
          .ant-skeleton-avatar {
            border-radius: 100%;
          }
        } 
      }
    }
  }
  &::-webkit-scrollbar {
    width: 0.3rem;
    height: 0.3rem;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 0.8rem;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $color-primary;
    border-radius: 0.8rem;
    height: 0.3rem;
  }
}
</style>
