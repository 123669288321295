<template>
  <a-modal
    :visible="visible"
    @cancel="closeModal"
    :footer="null"
    class="view-feed-modal"
    :title="null"
  >
    <div class="view-feed-modal-container">
      <a-row :gutter="[0, 0]">
        <a-col :xs="24" :sm="24" :md="14" :lg="15" :xl="15">
          <div
            class="view-feed-modal-container__image"
            :style="{
              backgroundImage: `url(${viewFeed.files[0].file})`,
            }"
          ></div>
        </a-col>
        <a-col :xs="24" :sm="24" :md="10" :lg="9" :xl="9">
          <div class="view-feed-modal-container__feed-content">
            <div class="view-feed-modal-container__feed-content--header">
              <div class="user">
                <div class="user__image">
                  <img :src="viewFeed.user.profile_picture" alt="Image" />
                </div>
                <div class="user__details">
                  <h1>{{ viewFeed.user.full_name }}</h1>
                  <!-- <p>Frontend Developer at Devroute</p> -->
                  <p class="time">{{ viewFeed.created_at }}</p>
                </div>
              </div>
            </div>
            <div class="view-feed-modal-container__feed-content--body">
              <div class="description" v-if="viewFeed.text.length > 0">
                <p>
                  {{ viewFeed.text }}
                </p>
              </div>
              <the-reaction-badge-counter
                  v-if="Object.keys(feedObject.feed_reactions).length > 0"
                  :totalArticleReactions="feedObject.feed_reactions"
                  :userReactionRepresentation="feedObject.users_reaction_representation"
                  :articleId="feedObject.id">
              </the-reaction-badge-counter>
              <div class="action-bar">
                <the-reactions
                  :articleId="viewFeed.id"
                  :userReaction="viewFeed.user_reaction"
                  @updateReaction="updateReaction"
                ></the-reactions>
                <span class="action-bar__line"></span>
                <button class="action-bar__btn">
                  <img
                    src="@/assets/icons/bookmark.svg"
                    style="width: 1.4rem;"
                  />
                  Save
                </button>
                <span class="action-bar__line"></span>
                <button class="action-bar__btn">
                  <img src="@/assets/icons/reply.svg" style="width: 1.8rem;" />
                  Share
                </button>
              </div>
            </div>
          </div>
        </a-col>
      </a-row>
    </div>
  </a-modal>
</template>

<script>
import TheReactions from "../BaseComponents/TheReactions.vue";
import TheReactionBadgeCounter from '../BaseComponents/TheReactionBadgeCounter.vue';

export default {
  data() {
    return {
      reRender: 0,
    };
  },
  components: {
    TheReactions,
    TheReactionBadgeCounter
  },
  props: {
    visible: Boolean,
    viewFeed: Object,
    feedObject: Object,
  },
  methods: {
    closeModal() {
      this.$emit("closeViewFeedModal", false);
    },
    updateReaction() {
      this.reRender = this.reRender + 1;
    },
  },
};
</script>
<style lang="scss">
.view-feed-modal {
  width: 100% !important;
  top: 5rem !important;
  max-width: 140rem;
  padding: 0 4rem;

  .ant-modal-content {
    box-shadow: 0px 0px 94px #00000047;

    .ant-modal-close {
      margin: 1.5rem 1.5rem 0 0;

      .ant-modal-close-x {
        padding: 0.5rem;
        width: 4rem;
        height: 3.8rem;
        line-height: normal;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $color-white;
        .anticon {
          color: $color-black;
          font-size: 2rem;
        }
      }
    }

    .ant-modal-body {
      .view-feed-modal-container {
        &__image {
          height: calc(100vh - 10rem);
          width: 100%;
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
          background-color: $color-black;

          @include respond(tab-port) {
            height: 40rem;
          }
        }

        &__feed-content {
          position: relative;

          &--header {
            padding: 2rem 2rem;
            position: sticky;
            top: 0;

            .user {
              display: flex;
              justify-content: flex-start;
              align-items: center;

              &__image {
                width: 5rem;
                height: 5rem;
                margin-right: 1rem;
                cursor: pointer;
                border-radius: 100%;

                img {
                  width: 100%;
                  height: auto;
                  border-radius: 100%;
                  object-fit: contain;
                  background-color: #ebebeb;
                }
              }

              &__details {
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                h1 {
                  font-size: 1.8rem;
                  font-family: $font-primary-bold;
                  line-height: 2.4rem;
                  margin-bottom: 0.3rem;
                  color: $color-black;
                  cursor: pointer;
                }

                p {
                  font-size: 1.4rem;
                  color: $color-black;
                  opacity: 0.6;
                  font-family: $font-primary-medium;
                  margin-bottom: 0;
                  line-height: 1.7rem;
                }

                .time {
                  font-size: 1.2rem;
                  line-height: 1.5rem;
                }
              }
              @include respond(phone-x-small) {
                &__details {
                  h1 {
                    font-size: 2rem;
                    line-height: 2.6rem;
                  }
                  .time {
                    font-size: 1.6rem;
                    line-height: 2.2rem;
                  }
                }
              }
            }
          }

          &--body {
            height: calc(100vh - 20.2rem);
            overflow-y: auto;

            @include respond(tab-port) {
              height: fit-content;
            }

            .description {
              padding: 0 2rem 2rem;

              p {
                font-size: 1.6rem;
                font-family: $font-primary;
                line-height: 2.2rem;
                margin-bottom: 0;
                color: $color-black;
                @include respond(phone-x-small) {
                  font-size: 1.8rem;
                  line-height: 2.4rem;
                }
              }
            }
            .user-react {
              padding: 1rem 1.5rem;
              border-top: 1px solid rgba(0, 0, 0, 0.2);
              width: calc(100% - 4rem);
              margin: 0 2rem;
              @include respond(phone-x-small) {
                padding: 1.5rem 2.6rem;
                &__outer-flex {
                  &--image-conatiner {
                    .image {
                      height: 2rem;
                      width: 2rem;
                      margin-left: -0.7rem;
                    }
                  }
                }
                &__name {
                  font-size: 1.6rem;
                  line-height: 2.2rem;
                }
              }
            }
            .action-bar {
              border-top: 1px solid $color-dark-grey-5;
              border-bottom: 1px solid $color-dark-grey-5;
              display: flex;
              align-items: center;
              width: calc(100% - 4rem);
              margin: 0 2rem;
              @include respond(tab-port) {
                border-bottom: none;
              }
              &__btn {
                width: 33.3%;
                border: none;
                outline: none;
                font-size: 1.6rem;
                font-family: $font-primary-medium;
                line-height: 2.2rem;
                gap: 1rem;
                color: $color-black;
                display: flex;
                align-items: stretch;
                justify-content: center;
                cursor: pointer;
                background-color: transparent;
                padding: 1.5rem 0;
                @include respond(phone-x-small) {
                  padding: 1.7rem 0;
                }
              }

              &__line {
                width: 1px;
                height: 2.3rem;
                background-color: $color-dark-grey-5;
                display: block;
              }

              .the-horizontal-reactions-bar {
                width: 33%;
                padding: 0;
                margin: 0 !important;

                &__reaction-status {
                  padding: 1.4rem 0;
                  border-end-start-radius: 2rem;
                  @include respond(phone-x-small) {
                    padding: 1.2rem 0;
                  }
                  &--reactions {
                    .image {
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      img {
                        width: 2rem !important;
                      }
                    }

                    .title {
                      font-size: 1.6rem;
                      font-family: $font-primary-medium;
                      line-height: 2.4rem;
                    }
                  }
                }
              }
            }

            &::-webkit-scrollbar {
              width: 0.5rem;
              height: 0.5rem;
            }

            &::-webkit-scrollbar-track {
              background-color: transparent;
              border-radius: 0.8rem;
            }

            &::-webkit-scrollbar-thumb {
              background-color: $color-primary;
              border-radius: 0.8rem;
              height: 5rem;
            }
          }
        }
      }
    }
  }
}
</style>
